html {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: #333;
    /* -webkit-font-smoothing: antialiased; */
    font-family: "Louize", "Georgia";
    font-size: 115%;
    line-height: 1.3;
}

@media screen and (min-width:1300px) {
    html {
        font-size: 140%;
    }
}

html,
body {
    overflow: hidden;
}

::-webkit-scrollbar {
    display: none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

::selection {
    background: black;
    color: rgba(255, 255, 255, 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
}

.sc {
    font-feature-settings: 'smcp';
    text-transform: lowercase;
}

a {
    color: #111;
    text-decoration: none;
    outline: 0 !important;
}

em em {
    font-style: normal;
}

.system-font {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 0.9rem;
    line-height: 1.4;
}

.system-font p,
.piece .system-font p {
    margin-bottom: 0.5rem;
}

button {
    background: white;
    border: 1px solid #aaa;
    border-radius: 2px;
    font-family: Figgins;
    margin-right: 0.5rem;
    padding: 6px 16px;
}

@media screen and (min-width:768px) {
    button:hover {
        border-color: #666;
    }
}

.shadow {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(0, 0, 0, 0.10);
}

.quiet {
    opacity: 0.5;
}